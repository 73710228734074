import { Icon } from '@/components/Icon';
import { useScheduled } from '@/hooks/useScheduled';
import { useStable } from '@/hooks/useStable';
import FooterEllipse from '@/mabra/public/image/course/banner-ellipse.svg';
import BannerLogo from '@/mabra/public/image/course/checkout-banner-logo.png';
import BannerImage from '@/mabra/public/image/course/mabra-banner-image.png';
import { mergeProps } from '@/utils/merge';
import { StandaloneCheckoutFooter, StandaloneCheckoutFooterProps } from 'base/components/CheckoutFooter';
import KlarnaBadgeLogo from 'base/public/image/klarna-badge.png';
import { getOcelotConfig } from 'lib/configs/ocelot';
import NextImage from 'next/image';
import { useMemo } from 'react';

export const MabraStandaloneCheckoutFooter: typeof StandaloneCheckoutFooter = (props) => {
  const { closeTime, discountEndTime, prices } = getOcelotConfig('features.payments');
  const { regular: regularPrice, discount: discountPrice } = prices ?? {};

  const isSignUpClosed = useScheduled(closeTime);

  const isLowerPrice = !useScheduled(discountEndTime);

  const background = useMemo(() => <FooterEllipse />, []);

  const logo = useMemo(
    () => (
      <>
        <NextImage src={BannerLogo} alt="Banner logo" className="h-[146px] w-[188px] md:h-[137px] md:w-[176px]" />
        <NextImage
          src={BannerImage}
          alt="Banner Image"
          className="absolute left-[64%] top-4 h-[75px] w-[89px] md:h-[63px] md:w-[74px]"
        />
      </>
    ),
    [],
  );

  const content = useMemo(
    () => (
      <>
        {logo}
        <div>
          <Icon name="circleCheck" /> Start 27 januari
        </div>
        <div>
          <Icon name="circleCheck" />{' '}
          <span className={`mr-1.5 ${isLowerPrice ? 'line-through' : ''}`}>{regularPrice} kr</span>
          {isLowerPrice && <span className="text-cherry-blossom-500">Nu endast {discountPrice} kr</span>}
        </div>
        {!isSignUpClosed && (
          <div data-last={true}>
            Anmäl dig nedan
            <Icon name="arrowDown" options={{ size: 'large', className: '!text-black !mr-0 mt-2' }} />
          </div>
        )}
      </>
    ),
    [logo, isSignUpClosed, isLowerPrice],
  );

  const footerStable = useStable({
    title: 'Anta utmaningen!',
    background,
    content,
    paymentGatewayLogo: !isSignUpClosed && (
      <>
        Betala med
        <NextImage src={KlarnaBadgeLogo} alt="Klarna logo" />
      </>
    ),
  } satisfies StandaloneCheckoutFooterProps);

  return <StandaloneCheckoutFooter {...mergeProps({ ...footerStable }, props)} />;
};
