import { Button } from '@/components/Button';
import { Icon } from '@/components/Icon';
import { useAppState } from '@/hooks/useAppState';
import { StandaloneComponent } from '@/types/component';
import { StandaloneArticle, StandaloneArticleProps } from 'base/components/Article';
import { useMemo } from 'react';

export const MabraStandaloneArticle: StandaloneComponent<StandaloneArticleProps> = ({ hide, ...props }) => {
  const [{ accessLevel, purchaseTag, publishedUrl }] = useAppState();

  const excludeUrls = ['/nyttig-mat/21-dagar-fore-start/10551729'];

  const isExcluded = excludeUrls.includes(publishedUrl ?? '');

  const showGoBackBanner = accessLevel === 'purchase' && purchaseTag === 'mabra_fitness_course' && !isExcluded;

  const goBackBanner = useMemo(
    () => (
      <div className="-ml-6 flex w-screen justify-center bg-yellow-sea-100 px-3 py-8 sm:mx-auto sm:w-article-lg sm:max-w-full">
        <Button
          href="https://www.mabra.com/21-dagar-start"
          content={
            <>
              <Icon name="chevronLeft" options={{ size: 'medium' }} />
              Tillbaka till startsidan
            </>
          }
          options={{ className: 'w-fit' }}
        />
      </div>
    ),
    [],
  );

  return (
    <StandaloneArticle
      bodytext={{ after: showGoBackBanner && goBackBanner }}
      hide={{ ...hide, badge: true }}
      {...props}
    />
  );
};
