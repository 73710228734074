import { useStable } from '@/hooks/useStable';
import { withTrackingAttributes } from '@/utils/withTrackingAttributes';
import { StandaloneCheckout } from 'base/components/Checkout';
import Tracking from 'base/components/TrackingComponent';
import { getOcelotConfig } from 'lib/configs/ocelot';

export const MabraStandaloneCheckout: typeof StandaloneCheckout = (props) => {
  const { closeTime } = getOcelotConfig('features.payments');

  const paymentStable = useStable({
    closed: {
      time: closeTime,
      copy: 'Anmälan är nu stängd, håll utkik i våra kanaler för nästa tillfälle.',
    },
    options: {
      $form: {
        className: 'cts-tracking-object',
        ...withTrackingAttributes({
          category: 'signup_box',
          name: 'klarna_payment',
          extraInfo: [{ campaign: 'mabra_course_21_dagar' }],
        }),
      },
    },
    props,
  });

  return (
    <Tracking>
      <StandaloneCheckout {...paymentStable} />
    </Tracking>
  );
};
