import { tw } from '@/utils/tw';
import BaseCourseCountdownTheme from 'base/components/CourseCountdown/theme';

const Button = tw.theme({
  extend: BaseCourseCountdownTheme.Button,
  variants: {
    size: {
      medium: [
        'px-3',
        'pt-1',
        'pb-1.5',
        'text-headline-xs',
        'gap-1.5',
        'after:hidden',
        'md:px-6',
        'md:pt-2',
        'md:pb-2.5',
        'md:gap-2.5',
        'md:after:h-0.25',
        'md:after:w-full',
      ],
    },
  },
});

const Timer = tw.theme({
  extend: BaseCourseCountdownTheme.Timer,
  slots: {
    title: `max-w-[600px] text-headline-sm md:mb-5 md:text-headline-md`,
    duration: `border-yellow-sea-400 w-[63px] justify-start border text-headline-md md:h-[70px] md:w-20 md:text-headline-lg`,
    durationLabel: `md:text-headline-xs`,
    group: `gap-1.5 sm:gap-3`,
    finished: `md:max-w-[580px]`,
  },
});

const CourseCountdown = tw.theme({
  extend: BaseCourseCountdownTheme,
  slots: {
    base: `bg-yellow-sea-100 relative flex flex-col items-center gap-3 bg-[url(/image/course/confirmation-banner-background.svg)] bg-[length:200%_180%] bg-bottom p-6 sm:bg-[length:150%_160%] md:gap-4 md:gap-5 md:bg-[length:130%_160%] lg:bg-[length:110%_160%]`,
    logo: `absolute left-6 top-6 hidden w-36 md:block`,
    description: `text-center text-headline-xs md:max-w-[820px] md:text-headline-sm`,
  },
});

export default Object.assign(CourseCountdown, { Button, Timer });
