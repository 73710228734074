import { tw } from '@/utils/tw';
import BaseAuthorDetailsTheme from 'base/components/AuthorDetails/theme';

const { SoMe } = BaseAuthorDetailsTheme;

const Image = tw.theme({
  extend: BaseAuthorDetailsTheme.Image,
  variants: {
    variant: {
      default: {
        base: ['rounded-none'],
      },
      alternative: {},
    },
  },
});

const AuthorDetails = tw.theme({
  extend: BaseAuthorDetailsTheme,
  variants: {
    variant: {
      default: {
        base: ['bg-white', 'max-w-article-lg', 'gap-x-6', 'm-6', 'mb-0'],
        caption: ['text-headline-xs'],
        description: ['text-body-sm', 'mt-1', 'mb-3', 'sm:mt-3'],
        email: ['text-cherry-blossom-500', 'text-body-sm', 'mt-2'],
        headline: ['text-headline-sm', 'mb-0.5', 'sm:1', 'mt-4', 'sm:mt-0'],
        soMeGroup: ['gap-x-3', 'mt-3', 'mb-3', 'sm:1.5'],
      },
    },
  },
});

export default Object.assign(AuthorDetails, { Image, SoMe });
