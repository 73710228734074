import { tw } from '@/utils/tw';
import BaseNavigationTheme from 'base/components/Navigation/theme';

const Button = tw.theme({
  extend: BaseNavigationTheme.Button,
  variants: {
    size: {
      small: ['text-headline-2xs', 'sm:text-headline-xs', '!px-2', 'sm:!px-3', 'md:!px-5'],
    },
  },
});

const BaseLinkVariants = BaseNavigationTheme.Link.variants;

const Link = tw.theme({
  variants: {
    colors: {
      ...BaseLinkVariants.colors,
      bar: [''],
    },
    size: {
      ...BaseLinkVariants.size,
      drawer: ['py-3.5', 'sm:py-2', 'gap-2', 'sm:gap-1'],
    },
    variant: {
      bar: ['text-preheadline', 'self-center'],
      drawerPrimary: ['text-preamble-sm', 'block', 'flex', 'items-center'],
      drawerSecondary: ['text-headline-xs', 'block'],
    },
  },
});

export default Object.assign({}, { Button, Link });
