import { tw } from '@/utils/tw';
import BaseArticleTeaserBodytextTheme from 'base/components/ArticleTeaser/Bodytext/theme';

const { Image } = BaseArticleTeaserBodytextTheme;

const ArticleTeaserBodytext = tw.theme({
  extend: BaseArticleTeaserBodytextTheme,
  slots: {
    category: `text-preheadline`,
    content: `gap-4`,
    description: `text-headline-xs md:text-headline`,
    footer: `text-preheadline`,
    group: `gap-0`,
    headline: `normal-case text-preheadline`,
  },
  variants: {
    variant: {
      fourth: {
        content: `shadow-[0px_0px_7px_2px] shadow-gray-100`,
      },
    },
  },
  defaultVariants: {
    variant: 'fourth',
  },
});

export default Object.assign(ArticleTeaserBodytext, { Image });
