import { OptionalLink } from '@/components/OptionalLink';
import { useAppState } from '@/hooks/useAppState';
import { MabraStandaloneBadge, MabraStandaloneBadgeProps } from '@/mabra/components/Badge';
import type { StandaloneComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { withTrackingAttributes } from '@/utils/withTrackingAttributes';
import { StandaloneArticleTeaserDefaultProps } from 'base/components/ArticleTeaser/Default';
import { ArticleTeaser } from 'base/components/ArticleTeaser/Default/ArticleTeaser';
import Tracking from 'base/components/TrackingComponent';
import { ArticleBadgeData } from 'lib/labrador/articleBadge';

export interface MabraStandaloneArticleTeaserProps extends StandaloneArticleTeaserDefaultProps {
  badge?: ArticleBadgeData;
  articleType?: string;
  options?: StandaloneArticleTeaserDefaultProps['options'] & { $badge?: MabraStandaloneBadgeProps['options'] };
}

export const MabraStandaloneArticleTeaserDefault: StandaloneComponent<MabraStandaloneArticleTeaserProps> = ({
  caption,
  description,
  footer,
  header,
  headline,
  image,
  kicker,
  links,
  badge,
  articleType,
  ...props
}) => {
  const [{ metaPageType }] = useAppState();

  const ctsAttrs =
    metaPageType === 'courses'
      ? withTrackingAttributes({
          category: 'teaser',
          name: headline as string,
          extraInfo: [{ campaign: 'mabra_course_21_dagar', type: articleType }],
          url: links?.article?.href,
        })
      : {};

  return (
    <Tracking options={{ ctsAttrs }}>
      <ArticleTeaser {...props}>
        {header && <ArticleTeaser.Header>{header}</ArticleTeaser.Header>}
        <OptionalLink
          {...mergeProps(
            {
              options: {
                className: props.options?.variant === 'horizontal' ? 'col-span-2' : '',
              },
            },
            links?.article,
          )}
          content={<ArticleTeaser.Image {...mergeProps(props?.options?.$image, image)} />}
        />
        {badge && <MabraStandaloneBadge data={badge} variant="teaser" options={props.options?.$badge} />}
        <ArticleTeaser.Group>
          {caption && (
            <OptionalLink {...links?.caption} content={<ArticleTeaser.Caption>{caption}</ArticleTeaser.Caption>} />
          )}
          {headline && (
            <OptionalLink {...links?.article} content={<ArticleTeaser.Headline>{headline}</ArticleTeaser.Headline>} />
          )}
          {description && <ArticleTeaser.Description>{description}</ArticleTeaser.Description>}
          {kicker && <ArticleTeaser.Description>{kicker}</ArticleTeaser.Description>}
        </ArticleTeaser.Group>
        {footer && <ArticleTeaser.Footer>{footer}</ArticleTeaser.Footer>}
      </ArticleTeaser>
    </Tracking>
  );
};
