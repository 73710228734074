import { Button } from '@/components/Button';
import { Icon } from '@/components/Icon';
import { useAppState } from '@/hooks/useAppState';
import BackgroundImage from '@/mabra/public/image/course/banner-ellipse.svg';
import BannerSplashImage from '@/mabra/public/image/course/banner-splash-image.png';
import BannerLogo from '@/mabra/public/image/course/mabra-banner-image.png';
import { StandaloneComponent } from '@/types/component';
import { getUrl } from '@/utils/getUrl';
import { mergeProps } from '@/utils/merge';
import { withTrackingAttributes } from '@/utils/withTrackingAttributes';
import { StandaloneBanner, StandaloneBannerProps } from 'base/components/Banner';
import { GenericSlotFactory } from 'base/components/GenericSlot';
import Tracking from 'base/components/TrackingComponent';
import NextImage from 'next/image';
import { useMemo } from 'react';
import BannerTheme from './theme';

const $ = GenericSlotFactory({ theme: BannerTheme });
const ContentWrapper = $({ as: 'div', slot: 'contentWrapper' });
const ContentHeadline = $({ as: 'p', slot: 'contentHeadline' });
const ContentDescription = $({ as: 'p', slot: 'contentDescription' });
const ContentLogo = $({ as: 'picture', slot: 'contentLogo' });
const Splash = $({ as: 'div', slot: 'splash' });
const SplashContainer = $({ as: 'div', slot: 'splashContainer' });

const ctsUrl = getUrl('/21-dagar');

const ctsAttrs = withTrackingAttributes({
  category: 'promotion_banner',
  name: 'teaser',
  url: ctsUrl ? ctsUrl.toString() : '/21-dagar',
  extraInfo: [{ campaign: 'mabra_course_21_dagar' }],
});

export const MabraStandaloneBanner: StandaloneComponent<StandaloneBannerProps> = (props) => {
  const [{ type, isFrontPage }] = useAppState();

  const backgroundImageMemo = useMemo(() => <BackgroundImage />, []);

  const contentMemo = useMemo(() => {
    const listElements = [
      'Nytt kostschema varje vecka.',
      'Receptbank med över 100 utvalda recept.',
      'Dagliga träningspass med PT:n Leila Söderholm.',
      'Möjlighet att ställa frågor direkt till våra experter!',
    ];
    return (
      <ContentWrapper variant={type}>
        {isFrontPage && (
          <ContentLogo>
            <NextImage src={BannerLogo} alt={'Banner Image'} />
          </ContentLogo>
        )}
        <SplashContainer>
          <NextImage
            src={BannerSplashImage}
            alt="Banner logo"
            className="mb-8 ml-8 h-[138px] w-[142px] sm:mb-0 md:h-[153px] md:w-[157px]"
          />
          <Splash>
            {isFrontPage && (
              <>
                <span className="!leading-none text-headline-2xs">Nu med</span>
                <br />
                ännu fler
                <br /> recept och
                <br /> träningsfilmer
              </>
            )}
            {!isFrontPage && <span>Snart stänger anmälan!</span>}
          </Splash>
        </SplashContainer>

        <ContentHeadline>Nya vanor på 21 dagar!</ContentHeadline>
        <ContentDescription>
          Vill du komma in i bra kost- och träningsvanor? Ge det 21 dagar! Anta vår roliga utmaning så ses vi 27
          januari.
        </ContentDescription>
        {isFrontPage && (
          <>
            <ContentDescription>Du får:</ContentDescription>
            <ul>
              {listElements.map((text: string, index: number) => (
                <li key={index}>
                  <ContentDescription>{text}</ContentDescription>
                </li>
              ))}
            </ul>
          </>
        )}
        <Button
          href="/21-dagar"
          options={{ size: 'small', colors: 'primary', className: 'mt-6 sm:mb-0 self-center !px-4 sm:px-5' }}
          content={
            <>
              Läs mer och anmäl dig här!
              <Icon name="chevronRight" options={{ className: 'hidden sm:block' }} />
            </>
          }
        />
      </ContentWrapper>
    );
  }, [isFrontPage, type]);

  return (
    <Tracking options={{ wrapChildren: true, ctsAttrs }}>
      <StandaloneBanner
        content={contentMemo}
        backgroundImage={backgroundImageMemo}
        {...mergeProps(
          {
            options: { variant: type },
          },
          props,
        )}
      />
    </Tracking>
  );
};
