import { BrandOcelotConfig } from '@/types/ocelotConfig';

const MabraConfig: BrandOcelotConfig = {
  search: {
    googleCustomSearch: {
      id: '008262495618786222540:jkyr6u7tke5',
    },
    placeholderImage: '/image/mabra-logo.svg',
  },
  analytics: {
    brandName: 'mabra',
    google: {
      measurement_id: 'G-LNWF9J81H2',
      dimensions: {
        page_id: '1',
        mobile_website: '2',
      },
      viewId: 141871978,
      hostname: 'www.mabra.com',
    },
    parsely: {
      siteId: 'mabra.com',
    },
    sifo: {
      propertyId: 'eb0feecd6999409eaa882b778d7131ea',
    },
    tealium: {
      account: 'aller-media-sweden',
      profile: 'main',
      environment: 'prod',
    },
    avo: {
      apiKey: 'blcuGqEsX2nT6VJtqDMv',
    },
    funnelRelay: {
      customerId: 'aller_e7bce_mabra',
      scriptSource: 'cdn-magiclinks.trackonomics.net/client/static/v2/aller_e7bce_mabra.js',
    },
    tags: ['funnelRelay', 'engagedTime', 'scrollDepthTracker', 'csMatchingKey', 'loggedInId', 'metadata', 'webVitals'],
    jwplayer: true,
  },
  features: {
    affiliateGallery: {
      enabled: false,
    },
    payments: {
      enabled: true,
      closeTime: 'Jan 27 2025 00:00:00 GMT+0200',
      discountEndTime: 'Jan 9 2025 00:00:00 GMT+0200',
      prices: {
        regular: '499',
        discount: '299',
      },
    },
    strossle: {
      enabled: true,
      id: '1dcf1f38-b388-43a8-adf6-4ccc6ee867b5',
    },
    login: {
      enabled: true,
      brandName: 'mabra__com',
    },
    infinityScrollArticle: {
      enabled: true,
    },
    banner: {
      enabled: false,
      excludedLabradorMetaPageTypes: ['courses'],
    },
  },
  pages: {
    instagram: {
      enabled: false,
    },
    '21-dagar': {
      enabled: true,
    },
  },
  seo: {
    schemaMarkup: {
      name: 'Måbra',
      foundingDate: '1976-01-01',
      alternateName: ['Må bra', 'Mabra', 'mabra.com'],
      sameAs: [
        'https://sv.wikipedia.org/wiki/M%C3%A5_bra',
        'https://www.wikidata.org/wiki/Q79014764',
        'https://www.pinterest.se/mabracom/',
        'https://www.facebook.com/tidningenmabra/',
        'https://www.instagram.com/mabra_com/',
      ],
      webPages: [
        '/halsoverktyg/nyhetsbrev',
        '/kontakt',
        '/om-cookies',
        '/alla-amnen',
        '/om-oss',
        '/halsoverktyg',
        '/halsoverktyg/nyhetsbrev',
        '/halsoverktyg/kaloribehov',
        '/halsoverktyg/midjahoftkvot',
        '/halsoverktyg/bmi',
        '/halsoverktyg/din-relativa-fettprocent',
        '/halsoverktyg/stress',
        '/vara-skribenter',
      ],
    },
  },
  ads: {
    video: {
      clickToPlay: 'UDNmLZsj',
      autoPlay: 'oP4lk47h',
    },
  },
  cmp: {
    onetrust_id: 'f92db731-1388-4521-b80a-47a40d660192',
  },
};

export default MabraConfig;
