import { tw } from '@/utils/tw';
import BaseRecommendedArticlesTheme from 'base/components/RecommendedArticles/theme';

const Skeleton = tw.theme({
  extend: BaseRecommendedArticlesTheme.Skeleton,
  base: ['h-[350px]'],
});

const ArticleTeaser = tw.theme({
  extend: BaseRecommendedArticlesTheme.ArticleTeaser,
  slots: {
    caption: [],
    description: ['text-body-sm', 'sm:text-body', '!mt-1'],
  },
  variants: {
    variant: {
      vertical: {
        caption: ['!text-preheadline', 'text-gray-600'],
      },
    },
  },
});

const RecommendedArticles = tw.theme({
  extend: BaseRecommendedArticlesTheme,
  slots: {
    base: [
      "bg-[url('/image/recommended-articles-background.jpg')]",
      "wings-[url('/image/recommended-articles-background.jpg')]",
      'py-4',
      'md:py-6',
      'my-6',
      'px-6',
    ],
    list: ['gap-3', 'sm:gap-6'],
    listItem: ['mb-6', 'sm:mb-0', 'md:mb-6'],
  },
});

export default Object.assign(RecommendedArticles, { ArticleTeaser, Skeleton });
