import { tw } from '@/utils/tw';

const Banner = tw.theme({
  slots: {
    base: [
      'group',
      'w-full',
      'p-4',
      'md:px-16',
      'flex',
      'flex-col',
      'relative',
      'overflow-hidden',
      'not-prose',
      'bg-yellow-sea-100',
    ],
    contentWrapper: ['flex', 'flex-col'],
    content: [
      'w-full',
      'p-4',
      'md:px-10',
      'mx-auto',
      '[&_ul]:ml-5',
      '[&_li]:mt-2',
      'bg-white',
      'sm:shadow-yellow-sea-300',
      'relative',
      'flex',
      'flex-col',
      'sm:shadow-[0px_0px_0px_2px]',
      '[&_ul]:list-disc',
    ],
    backgroundImage: ['text-yellow-sea-400', 'w-[1216px]', 'lg:w-full', 'absolute', 'bottom-0', 'right-0'],
    contentHeadline: ['text-headline-sm', 'md:text-headline-md', '!font-bold', 'md:text-center'],
    contentDescription: ['text-preheadline-md', 'mb-0', 'mt-4', '[li_&]:mt-0'],
    contentLogo: [
      'z-1',
      'h-16',
      'w-[74px]',
      'md:h-[71px]',
      'md:w-[83px]',
      'absolute',
      'top-2.5',
      'left-2.5',
      'md:top-2',
      'md:left-2',
    ],
    splash: [],
    splashContainer: [],
  },
  variants: {
    variant: {
      front: {
        base: ['md:px-27', 'md:py-8'],
        content: ['max-w-[624px]', 'md:px-24', 'md:pt-8'],
        splash: [
          'h-[104px]',
          'w-[138px]',
          'md:h-[121px]',
          'md:w-[161px]',
          'bg-[url(/image/course/banner-splash-background.svg)]',
          'pr-0.5',
          'pt-1.75',
          'text-center',
          'text-headline-xs',
          '!leading-[1.125]',
          'md:text-[18px]',
          'absolute',
          'top-15',
          'left-30',
          'sm:top-8',
          'sm:left-39',
          'md:top-28',
          'md:left-16',
          'lg:top-24',
          'lg:left-30',
        ],
        splashContainer: ['relative', 'md:absolute', 'md:-right-15', 'md:-top-5'],
      },
      article: {
        base: ['pt-20', 'md:pb-5'],
        content: ['max-w-[472px]', 'pt-18', 'md:pt-20'],
        contentHeadline: ['text-center'],
        contentDescription: ['text-center'],
        splash: [
          'flex',
          '[&_span]:self-center',
          'absolute',
          'bg-[url(/image/course/banner-splash-background.svg)]',
          'bg-no-repeat',
          'h-[85px]',
          'w-[105px]',
          'md:h-[98px]',
          'md:w-[132px]',
          'top-10',
          'left-36',
          'md:left-42',
          'text-headline-2xs',
          'md:text-headline-xs',
          'text-center',
        ],
        splashContainer: ['absolute', '-top-18', 'left-15'],
      },
    },
  },
  defaultVariants: {
    variant: 'front',
  },
});

export default Banner;
