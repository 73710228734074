import { tw } from '@/utils/tw';
import BaseButtonTheme from 'base/components/Button/theme';

const { Icon } = BaseButtonTheme;

const Button = tw.theme({
  extend: BaseButtonTheme,
  base: [
    'after:shadow-[0px_0px_2px_2px]',
    'after:rounded-[50%]',
    'after:-z-50',
    'after:absolute',
    'after:-bottom-0.5',
    'after:left-0',
    'border-2',
    'bg-origin-border',
    'relative',
  ],
  variants: {
    colors: {
      primary: [
        'active:bg-gradient-to-b',
        'active:from-cherry-blossom-300',
        'active:to-cherry-blossom-200',
        'after:bg-black/10',
        'after:shadow-black/10',
        'from-cherry-blossom-300 bg-gradient-to-b to-[#E1727D]',
        'border-transparent',
        'focus:bg-gradient-to-b',
        'focus:from-cherry-blossom-300',
        'focus:ring-cannon-pink-400',
        'focus:to-cherry-blossom-200',
        'hover:bg-cherry-blossom-300',
        'hover:to-cherry-blossom-300',
        'text-black',
      ],
      secondary: [
        'active:bg-gradient-to-b',
        'active:from-cherry-blossom-300',
        'active:text-black',
        'active:to-cherry-blossom-200',
        'after:shadow-none',
        'bg-white',
        'border-cherry-blossom-400',
        'focus:active:border-b-cherry-blossom-200',
        'focus:active:border-t-cherry-blossom-300',
        'focus:active:border-transparent',
        'focus:bg-gradient-to-b',
        'focus:border-cannon-pink-400',
        'focus:from-cherry-blossom-300',
        'focus:hover:border-cannon-pink-400',
        'focus:ring-cannon-pink-400',
        'focus:text-black',
        'focus:to-cherry-blossom-200',
        'hover:bg-cherry-blossom-300',
        'hover:border-cherry-blossom-300',
        'hover:text-black',
        'text-cherry-blossom-500',
      ],
    },
    size: {
      small: ['px-5', 'pt-1', 'pb-1.5', 'text-headline-xs', 'gap-1.5', 'after:hidden'],
      medium: ['px-6', 'pt-2', 'pb-2.5', 'text-headline-xs', 'gap-2.5', 'after:h-0.25', 'after:w-full'],
      large: ['px-6', 'pt-3', 'pb-3.5', 'text-headline-xs', 'gap-2.5', 'after:h-0.25', 'after:w-full'],
    },
  },
});

export default Object.assign(Button, { Icon });
